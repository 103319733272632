// an IIFE to prevent conflicts with other JS code

(function ($, window, document, undefined) {
	var tna = {
		init: function () {
			this.Components.init();
		},

		Components: {
			init: function () {
				// Variables
				this.bodyDir = $("body").attr("dir");

				// Methods
				// this.weHaveJs(); used in onload
				this.elementMatchHeight();
				this.headroom();
				// this.setTopPadding(); // used in the on load function
				this.menus();
				this.popup();
				// this.bootstrapCollapse();
				// this.pageNavigation();
				this.sliders();
				// this.langswitch();
			},

			langswitch: function () {
				var currentUrl = window.location.href;
				var langSwitchElement = $("#lang-switch");
				var body = document.querySelector("body");
				var bodyDir = window.getComputedStyle(body).direction;
				var replaceUrl;
				if (bodyDir == "rtl") {
					replaceUrl = currentUrl.replace("/ar", "/en");
					langSwitchElement.attr("href", replaceUrl);
				} else {
					replaceUrl = currentUrl.replace("/en", "/ar");
					langSwitchElement.attr("href", replaceUrl);
				}
			},

			weHaveJs: function () {
				const body = $("body");
				body.removeClass("no-js");
			},

			elementMatchHeight: function () {
				$(".mh").matchHeight({ byRow: false });
			},

			setTopPadding: function () {
				const pageWrapper = $(".page-wrapper");
				const headerHeight = $(".header").outerHeight();

				pageWrapper.css("padding-top", headerHeight);
			},

			headroom: function () {
				$(window).scroll(function () {
					const scroll = $(window).scrollTop();
					var window_width = jQuery(window).width();

					if (scroll > 100) {
						$(".header").addClass("scrolled");
					} else {
						$(".header").removeClass("scrolled");

						if (window_width < 992) {
							tna.Components.setTopPadding();
						}
					}
				});
			},

			popup: function () {
				const popup = $(".popup");

				if (popup.length) {
					$(".popup").magnificPopup();
				}
			},

			// Stop bootstrap collapse from closing all accordions
			bootstrapCollapse: function () {
				$("a.collapse-section__title").on("click", function (e) {
					// console.log(typeof ($('.collapse-sections').attr('data-keep-one').tobot));
					if (
						$(".collapse-sections").attr("data-keep-one") == "true"
					) {
						console.log("in");
						if (
							$(this)
								.next()
								.hasClass("in")
						) {
							e.stopPropagation();
							// You can also add preventDefault to remove the anchor behavior that makes
							// the page jump
							e.preventDefault();
						}
					}
				});
			},

			pageNavigation: function () {
				$(".main-menu > ul").onePageNav({
					begin: function () {
						//Hack so you can click other menu items after the initial click
						$("body").append(
							'<div id="device-dummy" style="height: 1px;"></div>'
						);
					},
					end: function () {
						$("#device-dummy").remove();
					}
				});
			},

			menus: function () {
				const search = $(".search");
				const mainMenu = $(".main-menu");
				const mainMenuContent = $(".main-menu > ul");
				const mainMenuItems = mainMenuContent.children(
					"li:not(.top-menu-item)"
				);
				const hamburger = document.querySelector(
					".mobile-menu-trigger"
				);

				// Add top menu items to mobile menu
				mainMenuContent.append(
					search.clone().addClass("top-menu-item")
				);

				// Define sub menu items
				mainMenuContent
					.find("li")
					.has("ul")
					.addClass("has-sub-menu");

				// Define the last visiable item
				mainMenuItems.last().addClass("menu-edge");

				// Initialize mmenu
				const MainMenuInit = mainMenu.mmenu(
					{
						rtl: $("body").attr("dir") === "rtl",
						extensions: ["position-right"]
					},
					{
						// configuration
						clone: true,
						offCanvas: {
							pageSelector: ".page-wrapper"
						},
						classNames: {
							fixedElements: {
								fixed: "mm-fixed"
							}
						}
					}
				);

				const mainMenuAPI = $(MainMenuInit).data("mmenu");

				// On click
				hamburger.addEventListener("click", () => {
					if (hamburger.classList.contains("is-active")) {
						mainMenuAPI.close();
					} else {
						mainMenuAPI.open();
					}
				});

				mainMenuAPI.bind("open:finish", () => {
					setTimeout(function () {
						hamburger.classList.add("is-active");
					}, 100);
				});

				mainMenuAPI.bind("close:finish", () => {
					setTimeout(function () {
						hamburger.classList.remove("is-active");
					}, 100);
				});

				// Enable double tap to go
				$(".main-menu li:has(ul)").doubleTapToGo();
			},

			sliders: function () {
				const sliders = $(".slider");
				const captionSlider = $(".caption-slider");

				const imageGallery = $(".image-gallery");
				imageGalleryNumberofSlides = imageGallery.children().length;
				if (captionSlider.length) {
					captionSlider.each((i, slider) =>
						$(slider).slick({
							slidesToShow: 1,
							slidesToScroll: 1,
							dots: true,
							adaptiveHeight: true,
							arrows: false,
							draggable: false,
							autoplay: true,
							autoplaySpeed: 3500,
							fade: true,
							asNavFor: sliders,
							rtl: this.bodyDir === "rtl",
							pauseOnHover: false,
						})
					);
				}

				if (sliders.length) {
					sliders.each((i, slider) =>
						$(slider).slick({
							slidesToShow: 1,
							slidesToScroll: 1,
							dots: false,
							arrows: false,
							draggable: false,
							asNavFor: captionSlider,
							rtl: this.bodyDir === "rtl",
						})
					);
				}

				if (imageGallery.length) {

					imageGallery.on("init", function (slick) {
						const customSlideDots = $(".slick-dots li");
						let precentage = 100 / imageGalleryNumberofSlides;
						precentage += "%";

						customSlideDots.each((i, dot) => {
							$(dot).css("width", precentage);
						})

					});

					imageGallery.each((i, gallery) => {
						$(gallery).slick({
							slidesToShow: 2,
							slidesToScroll: 1,
							rtl: this.bodyDir === "rtl",
							dots: true,
							arrows: false,
							// focusOnSelect: true,
							// centerMode: true
						});
					});
				}
			},

			// setHeroSlideHeight: function () {
			// 	const heroSlider = $(".hero-slider");
			// 	const slides = heroSlider.find(".slide");
			// 	const slideHeadings = heroSlider.find(".slide .slide__heading");

			// 	const windowHeight = $(window).height();
			// 	const headerHeight = $(".header").outerHeight();
			// 	const footerHeight = $(".footer").outerHeight();
			// 	const bleed = footerHeight;

			// 	// Set the slides height
			// 	if ($(".header").hasClass("transparent")) {
			// 		slides.css("min-height", windowHeight);
			// 	} else {
			// 		slides.css(
			// 			"min-height",
			// 			windowHeight - headerHeight - bleed
			// 		);
			// 	}

			// 	// Center slide content
			// 	setTopOffset(slideHeadings);

			// 	function setTopOffset(elements) {
			// 		elements.each((i, element) => {
			// 			const $this = $(element);
			// 			const parentSlideHeight = $this
			// 				.closest(".slide")
			// 				.outerHeight();
			// 			const topDistance = parentSlideHeight / 2;

			// 			$this.css("margin-top", topDistance);
			// 		});
			// 	}
			// },
		}
	};

	$(document).ready(function () {
		tna.init();
	});

	$(window).on("resize", () => {
		// Todo: Group all on resize calls here.
		tna.Components.setTopPadding();
		// tna.Components.setHeroSlideHeight();
	});

	$(window).on("load", () => {
		tna.Components.setTopPadding();
		tna.Components.weHaveJs();
	});
	$(function () {
		$('.expander-link').click(function () {
			$('.our-work-section .our-work-box:hidden').slice(0, 6).show();
			if ($('.our-work-section .our-work-box').length == $('.our-work-section .our-work-box:visible').length) {
				$('.expander-link').hide();
			}
		});
	
	});
})(jQuery, window, document);

$('input[type="file"]').on('change', function () {
	debugger;
	var dataId = $(this).data('id');
	var File = document.getElementById($(this).attr("id")).files[0];
	$('#' + dataId).val(File.name);
});
$('.flipcontainer').on('mouseenter', function(event){
	$(this).children('.first-layer').css({ 'z-index': '0','transform': 'rotateY(180deg)'})
	$(this).children('.second-layer').css({ 'z-index': '1','transform': 'rotateY(0deg)'})
})
$('.flipcontainer').on('mouseleave', function(){
	$(this).children('.second-layer').css({ 'z-index': '0','transform': 'rotateY(-180deg)'})
	$(this).children('.first-layer').css({ 'z-index': '1','transform': 'rotateY(0deg)'})
})